import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';

registerLocaleData(localeIT);

@Pipe({
  name: 's1Currency'
})
export class S1CurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): any
  transform(value: number, ...args: unknown[]): string {

    if (args.length > 0 && args[0]) { // se passo true come parametro mostro tutte le cifre dicimali possibili (max 9)
      return super.transform(value, 'EUR', 'symbol', '1.2-9', 'it');
    }

    return super.transform(value, 'EUR', 'symbol', '1.2-2', 'it');

  }

}
