import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../../core/interfaces/product-sale.interface';

@Component({
  selector: 'app-price-sort-tabs',
  templateUrl: './price-sort-tabs.component.html',
  styleUrls: ['./price-sort-tabs.component.scss']
})
export class PriceSortTabsComponent {

  @Input() products: Product[];

  @Output() productsSortChange = new EventEmitter<Product[]>();

  sortPriceAsc: string = '';

  constructor() { }

  sortResults() {
    if (this.sortPriceAsc === "t") {
      this.products.sort((a, b) => a?.customFieldsExternal?.feeCalculated - b?.customFieldsExternal?.feeCalculated);
    } else if (this.sortPriceAsc === "f") {
      this.products.sort((a, b) => b?.customFieldsExternal?.feeCalculated - a?.customFieldsExternal?.feeCalculated);
    } else {
      this.products.sort(() => Math.random() - 0.5); // Random
    }
    this.productsSortChange.emit(this.products);
  }

}
