<!-- ISTRUCTION/TIP -->
<div class="row align-items-center mt-3">
  <div class="col">
    <div class="card original bd-callout">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto col-icon-container"><i class="fas fa-info-circle fa-2x"></i></div>
          <div class="col">{{'productManagement.tips_threshold_section' | translate}}</div>
        </div>
      </div>
    </div>
  </div> 
</div>
<div class="d-flex align-items-center justify-content-between add-threshold-attribute">
  <div class="required-field-txt">(*) {{ 'base.required_field' | translate }}</div>
  <button (click)="addThresholdAttribute()" class="btn btn-info btn-action ml-2" type="button" [disabled]="thresholdAttributeList.length === maxLength_ThresholdAttributeList">
    {{'productManagement.label.add_name_threshold' | translate}}
  </button>
</div>
<ng-container *ngIf="thresholdAttributeList.length === maxLength_ThresholdAttributeList">
  <div class="d-flex my-5 justify-content-center align-items-center">
    <span class="icon-info"></span>
    <div class="w-400">
      {{ 'productManagement.thresholdsError.max_threshold' | translate}}
    </div>
  </div>
</ng-container>
<div class="threshold-list card-body row mt-3">
  <ng-container *ngFor="let attribute of thresholdAttributeList; let i = index; let first = first; let last = last">
    <div class="w-100">
      <div class="d-flex flex-row align-items-end flex-fill flex-wrap mb-3">
        <div class="d-flex align-items-end">
          <a *ngIf="!attribute.id && thresholdAttributeList.length > 1" (click)="deleteThresholdAttribute(i)" href="#"><i class="icon-close"></i></a>
          <div class="flex-fill ml-2 input-attribute-name">
            <label for="name">{{ 'productManagement.label.thresholdAttributeName' | translate }} * {{'common.visible_in_application' | translate}}</label>
            <input type="text"
              class="form-control"
              name="{{i}}-attribute-name"
              id="{{i}}-attribute-name"
              #thAtName="ngModel"
              (ngModelChange)="thresholdNameChanged(i, $event)"
              [ngModel]="attribute.name">
          </div>
        </div>

        <div class="d-flex flex-column align-self-stretch ml-5">
          <label class="mb-auto">{{ 'productManagement.label.cumulative' | translate}}</label>
          <div class="d-flex">
            <div class="d-flex flex-column align-self-end checkbox c-checkbox">
              <label class="w-100">
                <input type="checkbox"
                  name="{{i}}-cumulative"
                  #cumulative="ngModel"
                  [(ngModel)]="attribute.cumulative" />
                <span class="fa fa-check"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="add-threshold">
          <button (click)="addThreshold(i)" class="btn btn-info btn-action" type="button" [disabled]="attribute.name === null">{{'productManagement.label.addThreshold' | translate}}</button>
        </div>
      </div>

      <ng-container *ngFor="let threshold of attribute.productThresholds; let j = index; let first = first; let last = last">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-items-end ml-5 mb-2">
            <a *ngIf="!threshold.id && attribute.productThresholds.length > 1" (click)="deleteThreshold(i, j)" href="#">
              <i class="icon-close"></i>
            </a>
            <div class="form-group input-threshold-name p-0 m-0 ml-2">
              <label for="name">{{'productManagement.label.thresholdName' | translate}}*</label>
              <input required
                type="text"
                class="form-control"
                name="{{i}}-{{j}}-threshold-name"
                #thName="ngModel"
                [(ngModel)]="threshold.name">
            </div>
            <div class="form-group p-0 m-0 ml-2">
              <label for="code">{{'productManagement.label.min' | translate}}</label>
              <input type="number"
                #thresholdMin="ngModel"
                name="{{i}}-{{j}}-threshold-min"
                class="form-control"
                (ngModelChange)="thresholdChanged(i, j, 'min', $event)"
                placeholder=""
                [ngModel]="threshold.min">
            </div>
            <div class="form-group p-0 m-0 ml-2">
              <label for="code">{{'productManagement.label.max' | translate}}</label>
              <input type="number"
                #thresholdMax="ngModel"
                name="{{i}}-{{j}}-threshold-max"
                class="form-control"
                (ngModelChange)="thresholdChanged(i, j, 'max', $event)"
                [placeholder]="threshold.min + 1"
                [ngModel]="threshold.max">
            </div>
          </div>
          <div *ngIf="thName.touched && !thName.valid" class="align-self-center">
            <small *ngIf="thName.errors.required" class="text-danger">
              {{'productManagement.thresholdsError.name' | translate}}
            </small>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="thresholListValidity[i]">
        <div *ngIf="!thresholListValidity[i].thVal" class="text-danger thresholdsError text-center w-100">
          <small>{{ thresholListValidity[i].thKey | translate }}</small>
        </div>
      </ng-container>
      <hr *ngIf="!last" class="my-4" />
    </div>
  </ng-container>
  <!-- errori sui threshold attribute -->
  <ng-container *ngIf="thresholdAttributeList.length > 0">
    <div *ngIf="!thresholdAttributeNameValidity" class="text-danger thresholdsError text-center w-100">
      <small>{{ 'productManagement.label.sameNameThreshold' | translate }}</small>
    </div>
    <div *ngIf="!atLeastOneThresholdValidity" class="text-danger thresholdsError text-center w-100">
      <small>{{ 'productManagement.label.atLeastOneThreshold' | translate }}</small>
    </div>
  </ng-container>
  <div *ngIf="thresholdAttributeList.length === 0" class="text-center w-100 m-3">
    <div class="d-flex justify-content-center align-items-center">
      <span class="icon-info"></span>
      <div class="w-400">
          {{ 'productManagement.label.noThresholdsAttribute' | translate}}
      </div>
    </div>
  </div>
</div>
