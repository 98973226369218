import { RadioStateTranslation } from '../../../core/interfaces/admin.interface';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { State } from '../../../core/interfaces/admin.interface';

@Component({
  selector: 'app-radio-state',
  templateUrl: './radio-state.component.html',
  styleUrls: ['./radio-state.component.scss']
})
export class RadioStateComponent implements OnInit {
  @Output() selectedState = new EventEmitter<any>();
  @Input() ingresso: string;
  @Input() selected: State = State.ALL;
  @Input() radioStateTranslation: RadioStateTranslation[] = [];

  stateALLtext: string;
  stateACTIVEtext: string;
  stateINACTIVEtext: string;

  // property = enumerative, to be used in the template
  State = State;

  constructor() { }

  ngOnInit() {
    if (this.radioStateTranslation.length > 0) {
      this.stateALLtext = this.getTranslationKeyFromState(State.ALL);
      this.stateACTIVEtext = this.getTranslationKeyFromState(State.ACTIVE);
      this.stateINACTIVEtext = this.getTranslationKeyFromState(State.INACTIVE);
    } else {
      this.stateALLtext = 'all';
      this.stateACTIVEtext = 'active';
      this.stateINACTIVEtext = 'inactive';
    }
  }

  getTranslationKeyFromState(state: State): string {
    return this.radioStateTranslation.find(el => el.key === state).translationKey;
  }

  onChangeState(): void {
    this.selectedState.emit(this.selected);
  }

}
