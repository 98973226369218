export enum UserAction {
  NEW_USER = "newUser",
  EDIT_USER = "editUser",
  DELETE_USER = "deleteUser",
  EDIT_USER_PASSWORD = "EDIT_USER_PASSWORD",
  EDIT_USER_PROFILE = "EDIT_USER_PROFILE",
  ENABLE_USER = "ENABLE_USER",
  DISABLE_USER = "DISABLE_USER"
}

export enum CodeRole {
  COMPANY_ADMIN = "COMPANY_ADMIN",
  COMPANY_WORKER = "COMPANY_WORKER",
  RESELLER_ADMIN = "RESELLER_ADMIN",
  SUPERADMIN = "SUPERADMIN",
  COCLI_USER = "COCLI_USER"
}


export interface UserInfo {
  idUser?: number;
  username?: string;
  name?: string;
  surname?: string;
  // phone?: string,
  email?: string;
  profilePicture?: string;
  codeRole?: string;
  enabled?: boolean;
  idCompany?: number;
  idReseller?: Array<any>;
  resellerList?: Array<any>;
  businessName?: string;
  descRole?: string;
  canBeEditable?: boolean;
  canBeDeleted?: boolean;
  metadata?: IUserInfoMetadata
}

interface IUserInfoMetadata {
  checkStatusAPI? : {name: string, lastCheck: number}[];
}