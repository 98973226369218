import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

  menuItems: Array<any>;
  menuItemsObs = new BehaviorSubject<Array<any>>([]);


  constructor() {
    this.menuItems = [];
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>) {
    this.menuItems = [];
    items.forEach((item) => {
      this.menuItems.push(item);
    });
    this.menuItemsObs.next(this.menuItems);
  }

  getMenu(): Observable<any> {
    return this.menuItemsObs.asObservable();
  }

}
