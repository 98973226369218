import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownSelector } from '../../../core/interfaces/selector.interface';
import { MultiselectSettings, State } from '../../../core/interfaces/admin.interface';
import { CommonService } from '../../services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-park-datetime-select',
  templateUrl: './park-datetime-select.component.html',
  styleUrls: ['./park-datetime-select.component.scss']
})
export class ParkDatetimeSelectComponent implements OnInit {

  @Input() step: number = 0;
  
  @Output() formChange = new EventEmitter<any>();

  timeOptionsListFrom: DropdownSelector[] = [];
  timeOptionsListTo: DropdownSelector[] = [];

  inputForm: any = {
    multiselect: {
      timeFrom: null,
      timeTo: null
    },
    inputText: {
      dateFrom: null,
      dateTo: null,
      customerName: null,
      customerSurname: null,
      customerEmail: null
    },
    state: State.ALL
  };

  datepicker: any = {
    config: {
      customTodayClass: 'custom-today-class',
      dateInputFormat: 'DD MMMM YYYY',
      isAnimated: true,
      adaptivePosition: true,
      containerClass: 'theme-blue'
    },
    maxDate: null,
    minDate: new Date(),
    paymentDate: new Date(),
    maxDateOrder: new Date
  };

  multiselectSettings_base: MultiselectSettings = {
    singleSelection: true,
    text: this.translate.instant('productManagement.label.select_channel'),
    searchPlaceholderText: "Search",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
    autoPosition: false,
    tagToBody: false
  };
  multiselectSettings_Time: MultiselectSettings;

  /** 
   * Serve per avere la lista di Locale da utilizzare per assegnare dinamicamente locale a Datepicker
   */
  locales = listLocales();
  locale = this.translate.currentLang || 'it';

  constructor(
    private localService: BsLocaleService,
    private translate: TranslateService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setMultiselectSettings();
    this.localService.use( this.locale );
  }

  onDateSelect(dateFrom: boolean, date: Date): void {
    if (dateFrom) {
      if (date > this.inputForm.inputText.dateTo) {
        this.inputForm.inputText.dateTo = date;
      }

      this.inputForm.multiselect.timeFrom = null;
      this.inputForm.multiselect.timeTo = null;
    }

    this.mapTime(dateFrom ? false : true);

    this.formChange.emit(this.inputForm);
  }

  onTimeSelect(dateFrom: boolean): void {
    if (dateFrom) {
      this.mapTime(true);

      const timeFrom = this.inputForm.multiselect.timeFrom[0].id;
      const timeTo = this.inputForm.multiselect.timeTo ? this.inputForm.multiselect.timeTo[0].id : undefined;

      if (this.inputForm.inputText.dateTo < this.inputForm.inputText.dateFrom) {
        this.inputForm.multiselect.timeTo = null;
      } else if (this.commonService.sameDate(this.inputForm.inputText.dateTo, this.inputForm.inputText.dateFrom) && timeFrom > timeTo) {
        this.inputForm.multiselect.timeTo = null;
      }
    }

    this.formChange.emit(this.inputForm);
  }

  onTimeDeSelect(dateFrom: boolean): void {
    if (dateFrom) {
      this.inputForm.multiselect.timeFrom = null;
    } else {
      this.inputForm.multiselect.timeTo = null;
    }

    this.formChange.emit(this.inputForm);
  }

  // ---------- PRIVATE METHODS ---------- //

  private setMultiselectSettings(): void {
    this.multiselectSettings_Time = Object.assign({}, this.multiselectSettings_base);
  }

  /**
   * Method that manages time selects in order to display only the available values:
   * - On current day the first available result must be at least 3 hours after the current time
   * - If way out time is selected return must be at least 3 hours later if on the same day 
   */
  private mapTime(onlyTo: boolean = false) {

    if (!onlyTo) {
      this.timeOptionsListFrom = [];
    }
    
    this.timeOptionsListTo = [];

    const hourOffset = 3;

    const fromHour = this.commonService.isToday(this.inputForm.inputText.dateFrom) ? (new Date()).getHours() + hourOffset : 0;
    let toHour = this.commonService.isToday(this.inputForm.inputText.dateTo) ? (new Date()).getHours() + hourOffset : 0;

    // FROM and TO date are the same and time FROM is selected

    if (this.inputForm.inputText.dateFrom && this.commonService.sameDate(this.inputForm.inputText.dateFrom, this.inputForm.inputText.dateTo) && this.inputForm.multiselect.timeFrom !== null) { // Se from è selezionato
      toHour = Math.floor(this.inputForm.multiselect.timeFrom[0].id / 2) + 3;
    }

    for (let i = 0; i < 24; i++) {

      const hourFrom = ("00" + i).slice(-2) + ":00";
      const halfHour = ("00" + i).slice(-2) + ":30";

      const opt = {
        id: i * 2,
        itemName: `${hourFrom} `
      } as DropdownSelector;

      const opt2 = {
        id: i * 2 + 1,
        itemName: `${halfHour}`
      } as DropdownSelector;

      if (!onlyTo && i >= fromHour) {
        this.timeOptionsListFrom.push(opt);
        this.timeOptionsListFrom.push(opt2);
      }

      if (i >= toHour) {
        this.timeOptionsListTo.push(opt);
        this.timeOptionsListTo.push(opt2);
      }
      
    }

  }

}
