import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'covered'
})
export class CoveredPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(value: boolean, ...args: unknown[]): string {

    // arriva parametro uncovered, quindi
    // true = uncovered
    // false = covered

    if (value == null) {
      return "";
    }

    const key = "parking." + (value ? "uncovered": "covered");

    return `(${this.translate.instant(key)})`;
  }

}
