const packageJson = require('../../../package.json')


export function getApplicationVersion() {
    return packageJson.version;
}


export function getEndPointWithParams(endPoint) {
    var args = Array.prototype.slice.call(arguments, 1)
    var count = -1;
    return endPoint.replace(/:[a-zA-Z?]+/g, (match) => {
        count += 1;
        return args[count] !== undefined ? args[count] : match;
    });
}


export function dateFormatting(date) {
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let endDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return date.getFullYear() + "-" + month + "-" + endDate
}


export function timezoneAdjustment(viewValue) {
    // undo the timezone adjustment we did during the formatting
    viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
    // we just want a local date in ISO format
    return viewValue.toISOString();
}

// convert 2020-5-12 - 2020-6-13 in 12/5/2020 - 13/6/2020
export function convertPeriodFormat(period) {
    var splicer = " - ";
    var res = period.split(splicer);
    var startDate = fromISOtoUkFormat(res[0]);
    var endDate = fromISOtoUkFormat(res[1]);
    period = (startDate ? startDate : "inf") + splicer + (endDate ? endDate : "inf")
    return period;
}

export function fromISOtoUkFormat(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        return date.getDate() + '/'
            + (date.getMonth() + 1) + '/'
            + date.getFullYear();
    }
}

export function convertTimeInDate(timeString) {
    if (timeString === null) return null;
    var splicer = ":";
    var d = new Date();
    var res = timeString.split(splicer);
    var hh = res[0];
    var mm = res[1];
    d.setHours(hh);
    d.setMinutes(mm);
    d.setSeconds(0);
    return d;
}


export function getTotalPriceFromThreshold(quantity, thresholdAttributeList) {

    let partial = [];

    let pTh = thresholdAttributeList.productThresholds != undefined ? thresholdAttributeList.productThresholds : thresholdAttributeList;

    pTh.forEach(threshold => {

        if (quantity > threshold.max && threshold.max !== null) {
            if (threshold.min === 0) {
                partial.push((threshold.max - threshold.min) * threshold.price);
            } else {
                partial.push((threshold.max - threshold.min + 1) * threshold.price);
            }
            return
        }


        if (quantity <= threshold.max && quantity >= threshold.min) {
            if (threshold.min === 0) {
                partial.push((quantity - threshold.min) * threshold.price);
            } else {
                partial.push((quantity - threshold.min + 1) * threshold.price);
            }
        }

        if (threshold.max === null && quantity >= threshold.min) {
            if (threshold.min === 0) {
                partial.push((quantity - threshold.min) * threshold.price);
            } else {
                partial.push((quantity - threshold.min + 1) * threshold.price);
            }
        }

    });

    return partial.reduce((prev, curr) => prev + curr);
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }  
    return true;
}