import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslatorService {

  private defaultLanguage: string = 'it';

  private availablelangs = [
    { code: 'en', text: 'English' },
    { code: 'it', text: 'Italian' }
  ];

  constructor(public translate: TranslateService) {

    if (!translate.getDefaultLang()) {
      translate.setDefaultLang(this.defaultLanguage);
    }
    this.useLanguage();

  }

  getDefaultLanguage() {
    return this.translate.getDefaultLang();
  }

  useLanguage(lang: string = null) {
    this.translate.use(lang || this.translate.getDefaultLang());
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  getTranslationByKey(key: string): Observable<any> {
    return this.translate.get(key);
  }
}
