import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { S1HttpClientService } from "../../s1/services/s1-http-client.service";
import { RequestBody } from "../interfaces/admin.interface";
import { Faq, FaqDetail, FaqsAction } from "../interfaces/faqs.interface";
import { SettingsService } from "../settings/settings.service";
import { LoggingService } from "./log.service";
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';

@Injectable()
export class FaqsService {

    private typeAction: FaqsAction;

    constructor(
        private logger: LoggingService,
        private settings: SettingsService,
        private s1HttpService: S1HttpClientService
    ) { }

    setTypeAction(typeAction: FaqsAction) {
        this.typeAction = typeAction;
    }

    getTypeAction(): FaqsAction {
        return this.typeAction;
    }

    faqsList(reqBody: RequestBody, showUi?: boolean): Observable<any> {
        const endPoint = ENDPOINT.faq_all;
        return this.s1HttpService.post(endPoint, reqBody, showUi);
    }

    createFaqHeader(faq: Faq): Observable<any> {
        const endPoint = ENDPOINT.faq_header;
        return this.s1HttpService.post(endPoint, faq);
    }

    getFaqHeaderInfo(faqId: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.faq_info, faqId);
        return this.s1HttpService.get(endPoint);
    }

    editFaqHeader(faq: Faq): Observable<any> {
        const endPoint = ENDPOINT.faq_header;
        return this.s1HttpService.put(endPoint, faq);
    }

    // --------- FAQ DETAILS --------- //

    createFaqDetail(faqDetail: FaqDetail, showUi?: boolean): Observable<any> {
        const endPoint = ENDPOINT.faq_detail;
        return this.s1HttpService.post(endPoint, faqDetail, showUi);
    }

    editFaqDetail(faqDetail: FaqDetail, showUi?: boolean): Observable<any> {
        const endPoint = ENDPOINT.faq_detail;
        return this.s1HttpService.put(endPoint, faqDetail, showUi);
    }

    deleteFaqDetail(idFaqDetail: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.faq_detail_cancel, idFaqDetail);
        return this.s1HttpService.delete(endPoint);
    }

}
