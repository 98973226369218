import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss']
})
export class FilterContainerComponent implements OnInit {

  @Input() radioSection: boolean = true;

  @Output() searchFilterEvent = new EventEmitter<any>();
  @Output() resetFilterEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  searchFilter() {
    this.searchFilterEvent.emit();
  }

  onresetFilters() {
    this.resetFilterEvent.emit();
  }

}
