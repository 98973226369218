<ng-container #parkDateTime>
    <div class="row">
        <!-- CUSTOMER INFO -->
        <div class="form-group col-sm-4">
            <label for="name">{{ 'order.columns.client_name' | translate }}</label>
            <input name="name" type="text" [(ngModel)]="inputForm.inputText.customerName" class="form-control" />
        </div>
        <div class="form-group col-sm-4">
            <label for="surname">{{ 'order.columns.client_surname' | translate }}</label>
            <input name="surname" type="text" [(ngModel)]="inputForm.inputText.customerSurname" class="form-control" />
        </div>
        <div class="form-group col-sm-4">
            <label for="client_email">{{ 'order.columns.client_email' | translate }}</label>
            <input name="email" type="email" [(ngModel)]="inputForm.inputText.customerEmail" class="form-control" />
        </div>
    </div>
    
    <div class="row align-items-end">
        <!-- DATE FROM -->
        <div class="form-group col-sm-4">
            <label for="from_date">{{ 'form.select_dateFrom' | translate }}</label>
            <input class="form-control" autocomplete="off"
                required
                type="text"
                name="from_date"
                placeholder="{{'form.select_date' | translate}}"
                [(ngModel)]="inputForm.inputText.dateFrom"
                (ngModelChange)="onDateSelect(true, $event)"
                #dp="bsDatepicker"
                bsDatepicker
                [bsConfig]="datepicker.config"
                [maxDate]="datepicker.maxDate"
                [minDate]="datepicker.minDate"/>
        </div>
        <div class="form-group col-sm-2">
            <angular2-multiselect name="timeFrom"
                [data]="timeOptionsListFrom"
                [settings]="multiselectSettings_Time"
                [(ngModel)]="inputForm.multiselect.timeFrom"
                (onSelect)="onTimeSelect(true)"
                (onDeSelect)="onTimeDeSelect(true)">
            </angular2-multiselect>
        </div>
        <!-- Date to -->
        <div class="form-group col-sm-4">
            <label for="to_date">{{ 'form.select_dateTo' | translate }}</label>
            <input class="form-control" autocomplete="off"
                required
                type="text"
                name="to_date"
                placeholder="{{'form.select_date' | translate}}"
                [(ngModel)]="inputForm.inputText.dateTo"
                (ngModelChange)="onDateSelect(false, $event)"
                #dp="bsDatepicker"
                bsDatepicker
                [bsConfig]="datepicker.config"
                [maxDate]="datepicker.maxDate"
                [minDate]="inputForm.inputText.dateFrom || datepicker.minDate"/>
        </div>
        <div class="form-group col-sm-2">
            <angular2-multiselect name="timeTo"
                [data]="timeOptionsListTo"
                [settings]="multiselectSettings_Time"
                [(ngModel)]="inputForm.multiselect.timeTo"
                (onSelect)="onTimeSelect(false)"
                (onDeSelect)="onTimeDeSelect(false)">
            </angular2-multiselect>
        </div>
    </div>
</ng-container>