<ng-container>
    <div class="filter-text">{{'state.title' | translate}}</div>
    <div class="filter-checkbox">
        <span class="c-radio mr-2">
            <label>{{ 'state.'+stateALLtext | translate}}
                <input type="radio"
                    name="all"
                    [value]="State.ALL"
                    [(ngModel)]="selected"
                    (change)="onChangeState()" />
                <span class="fa fa-circle"></span>
            </label>
        </span>
        <span class="c-radio mr-2">
            <label>{{ 'state.'+stateACTIVEtext | translate}}
                <input type="radio"
                    name="active"
                    [value]="State.ACTIVE"
                    [(ngModel)]="selected"
                    (change)="onChangeState()" />
                <span class="fa fa-circle"></span>
            </label>
        </span>
        <span class="c-radio mr-2">
            <label>{{ 'state.'+stateINACTIVEtext | translate}}
                <input type="radio"
                    name="inactive"
                    [value]="State.INACTIVE"
                    [(ngModel)]="selected"
                    (change)="onChangeState()" />
                <span class="fa fa-circle"></span>
            </label>
        </span>
    </div>
</ng-container>
