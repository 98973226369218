<div [ngSwitch]="statusObliteration" [ngClass]="{'access-state': badgeType === BadgeType.accessState}">
    <div *ngSwitchCase="AccessChannelResponse.APPROVED_ADMIN"
        [ngClass]="badgeType === BadgeType.accessState ? 'approved' : 'badge badge-success'">
        {{ 'obliteration.filters.approved_admin' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.APPROVED"
        [ngClass]="badgeType === BadgeType.accessState ? 'approved' : 'badge badge-success'">
        {{ 'obliteration.filters.approved' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.APPROVED_OFFLINE"
    [ngClass]="badgeType === BadgeType.accessState ? 'approved-off' : 'badge badge-success'">
        {{ 'obliteration.filters.approved_off' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.APPROVED_FORCED"
        [ngClass]="badgeType === BadgeType.accessState ? 'approved' : 'badge badge-success'">
        {{ 'obliteration.filters.approved_forced' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.rejected' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_OFFLINE"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected-off' : 'badge badge-danger'">
        {{ 'obliteration.filters.rejected_off' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_UNKNOW_ACCESS_CHANNEL"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.REJECTED_UNKNOW_ACCESS_CHANNEL' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_UNKNOW_ACCESS_CHANNEL_OFFLINE"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected-off' : 'badge badge-danger'">
        {{ 'obliteration.filters.REJECTED_UNKNOW_ACCESS_CHANNEL_OFFLINE' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_ACCESS_CHANNEL_NOTVALID"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.REJECTED_ACCESS_CHANNEL_NOTVALID' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_ACCESS_CHANNEL_NOTVALID_OFFLINE"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected-off' : 'badge badge-danger'">
        {{ 'obliteration.filters.REJECTED_ACCESS_CHANNEL_NOTVALID_OFFLINE' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.OUT_OF_VALIDITY"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.OUT_OF_VALIDITY' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.OUT_OF_VALIDITY_OFFLINE"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected-off' : 'badge badge-danger'">
        {{ 'obliteration.filters.OUT_OF_VALIDITY_OFFLINE' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.OUT_OF_USABILITY"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.OUT_OF_USABILITY' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.OUT_OF_USABILITY_OFFLINE"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.OUT_OF_USABILITY_OFFLINE' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.CODE_NOT_EXIST"
        [ngClass]="badgeType === BadgeType.accessState ? 'code-not-exist' : 'badge badge-warning'">
        {{ 'obliteration.filters.code_not_exist' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.EXCEED_OBLITERATION"
        [ngClass]="badgeType === BadgeType.accessState ? 'exceed-ob' : 'badge-secondary exceed-ob'">
        {{ 'obliteration.filters.exceed_obliteration' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.CANCELED_ADMIN"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.canceled_admin' | translate | titlecase }}
    </div>
    <div *ngSwitchCase="AccessChannelResponse.REJECTED_MAX_OBLITERATION_TODAY_ACCESS_CHANNEL"
        [ngClass]="badgeType === BadgeType.accessState ? 'rejected' : 'badge badge-danger'">
        {{ 'obliteration.filters.rejected_max_obliteration_today_access_channel' | translate | titlecase }}
    </div>
    <div *ngSwitchDefault [ngClass]="{'undefined': badgeType === BadgeType.accessState}">
        {{ 'obliteration.filters.undefined' | translate | titlecase }}
    </div>
</div>