<!--<div class="form-group">
  <label for="prod-m">{{ 'form.user'| translate }}</label>
  <angular2-multiselect [data]="resellerList"
      [(ngModel)]="resellerSelected"
      [settings]="multiselectSettings_base"
      (onSelect)="onResellerSelect()">
  </angular2-multiselect>
</div>-->
<div class="row">
  <ng-container *ngIf="loggedUserInfo.role === CodeRole.SUPERADMIN">
    <div class="col-12">
        <label for="">{{ 'product_sales.reseller'| translate }}</label>
        <angular2-multiselect (onSelect)="onResellerSelect($event)"
            (onDeSelect)="onDeselectReseller()"
            [data]="resellerList"
            [(ngModel)]="resellerSelected"
            [settings]="multiselectSettings_base">
        </angular2-multiselect>
    </div>
  </ng-container>
  <div class="col-12" *ngIf="loggedUserInfo.role === CodeRole.COMPANY_ADMIN">
    <label for="">{{'base.rivendita' | translate}}</label>
    <angular2-multiselect (onSelect)="onResellerSelect($event)"
        (onDeSelect)="onDeselectReseller()"
        [data]="resellerList"
        [(ngModel)]="resellerSelected"
        [settings]="multiselectSettings_base">
    </angular2-multiselect>
  </div>
</div>
