<div class="row">
  <!-- Nome -->
  <div class="form-group col-sm-4">
    <label for="code">{{ 'form.fly_number' | translate }}</label>
    <input [(ngModel)]="info.flight"
      (ngModelChange)="updateInfo()"
      required
      type="text"
      id="code"
      name="flyNumber"
      placeholder="{{'form.enter_fly_number' | translate}}"
      class="form-control"
      ngModel
      #flyNumber="ngModel">
  </div>
  <!-- Data volo -->
  <s1-date-picker class="col-sm-4" [(date)]="info.date" (dateChange)="updateInfo()" [label]="'form.fly_date'"></s1-date-picker>
  <!-- Nome Leader -->
  <div class="form-group col-sm-4">
    <label for="codeThreshold">{{ 'form.leader_name' | translate }}</label>
    <input [(ngModel)]="info.groupLeaderName"
      (ngModelChange)="updateInfo()"
      required
      type="text"
      id="codeThreshold"
      name="groupLeaderName"
      placeholder="{{'form.enter_leader_name' | translate}}"
      class="form-control"
      ngModel
      #leaderName="ngModel">
  </div>
</div>
<div class="row">
  <div class="form-group col-sm-12">
    <label for="codeThreshold">{{ 'form.object' | translate }}</label>
    <textarea [(ngModel)]="info.note"
      (ngModelChange)="updateInfo()"
      type=""
      id="codeThreshold"
      name="object"
      class="form-control"
      ngModel
      #object="ngModel"
      rows="4" cols=""></textarea>
  </div>
</div>