export enum OwnerAction {
    NEW_OWNER = 'newOwner',
    EDIT_OWNER = 'editOwner',
    ENABLE_OWNER = 'enableOwner',
    DISABLE_OWNER = 'disableOwner'
}

export interface Owner {
    id?: number;
    name?: string;
    code?: string;
    state?: number;
    metadata?: OwnerMetadata;
}

export interface OwnerMetadata {
    apiKey?: string;
    apiBaseUrl?: string;
    apiSecretKey?: string;
    useAmountPassedViaAPI?: boolean;
    disclaimer?: { gdpr: string };
    purchaseManagerInfo?: PurchaseManagerInfo;
}

export interface EntryInfo {
    code?: string;
    description?: string;
}

export interface PurchaseManagerInfo {
    airportList?: EntryInfo[];
    baseUrlPath?: string;
    companyCodeOrder?: string;
    defaultAirport?: string;
    defaultProductType?: string;
    defaultTerminal?: string;
    keyIdHeader?: string;
    keyIdValue?: string;
    lang?: string;
    productCode?: string;
    productTypeList?: EntryInfo[];
    resellerCode?: string;
    salesChannelCode?: string;
    ticketConditions: TicketConditions;
}

export interface TicketConditions {
    maxDaysToCancelTicketFromPurchase: number;
    minimumTimeToModifyTicketInSeconds: number;
    minimumTimeToPurchaseInSeconds: number;
}