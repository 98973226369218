
<ng-container *ngIf="fullVersion">
  <div>{{ 'form.first_name' | translate }} <b>{{ item.additionalInfo.porterageData.name }}</b></div>
  <div>{{ 'form.last_name' | translate }} <b>{{ item.additionalInfo.porterageData.surname }}</b></div>
  <div>{{ 'form.email' | translate }} <b>{{ item.additionalInfo.porterageData.email }}</b></div>
  <div>{{ 'form.phone_number' | translate }} <b>{{ item.additionalInfo.porterageData.phone }}</b></div>
  <div>{{ 'form.fly_number' | translate }} <b>{{ item.additionalInfo.porterageData.flightNumber }}</b></div>
  <div *ngIf="item.additionalInfo.porterageData.flightDate" class="mb-3">{{ 'form.fly_date' | translate }}:
      <b>{{ item.additionalInfo.porterageData.flightDate | date: 'dd MMMM y':"":"it" }}</b>
  </div>
</ng-container>
<div *ngFor="let detail of item.additionalInfo.porterageData.details">
  <div *ngIf="detail.quantity > 0">
      {{ detail.quantity }}x {{ "porteraggio."+detail.code | translate }}
  </div>
</div>