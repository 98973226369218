import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss']
})
export class QuantitySelectorComponent implements OnInit {

  @Output() selectedQuantity = new EventEmitter<number>();
  @Output() minusClicked = new EventEmitter<any>();
  @Output() plusClicked = new EventEmitter<any>();
  @Input() inputQuantity: number = 0;
  @Input() disabled: boolean = false;
  @Input() disabledPlus: boolean = false;



  constructor() { }

  ngOnInit(): void {
  }

  minusAction(): void {
    if (this.inputQuantity - 1 >= 0 && !this.disabled) {
      this.selectedQuantity.emit(--this.inputQuantity);
      this.minusClicked.emit();
    }
  }

  plusAction(): void {
    if (!this.disabled && !this.disabledPlus) {
      this.selectedQuantity.emit(++this.inputQuantity);
      this.plusClicked.emit();
    }
  }

}
