import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingService } from './log.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';


/* BASE STRUCT RESPONSE
*  {
      "outcome":
        { "success":true,
          "code":null,
          "message":null,
          "description":null
        },
      "data":
        {"token":"SeIkZJC0hIeBYdJYlbYxq4q0Us+gAPqrC/42W0SJ+HEMLLSsMn6TRnBozdS4vR89",
          "name":"SUPER",
          "surname":"ADMIN",
          "enabled":false,
          "role":"SUPERADMIN"}
    }
*
*/

@Injectable()
export class AuthService {

  loggedUserObs = new BehaviorSubject<boolean>(false);

  

  constructor (private http: HttpClient, private logger: LoggingService) {}

  login(loginData: Object): any {
    return this.http
    .post<any[]>(environment.restBaseUrl + '/login', loginData, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          const dataResponse = res.data;
          this.logger.log("Service:", "SUCCESS", 200);
          localStorage.setItem("name", dataResponse.name);
          localStorage.setItem("surname", dataResponse.surname);
          localStorage.setItem("role", dataResponse.role);
          localStorage.setItem("token", dataResponse.token);
          localStorage.setItem("userInfo", JSON.stringify(dataResponse));
          localStorage.removeItem("idResellerChoosed");
          this.setLoggedUserState(true);
          return res;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          localStorage.clear();
          this.setLoggedUserState(false);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
      this.logger.log("Service Fail. Error:", error, 200);
        return throwError(error);
      }
    ));
  }

  /* EVENTI DI LOGIN */
  syncOnLoginEvent(): Observable<boolean> {
    return this.loggedUserObs.asObservable();
  }
  setLoggedUserState(value: boolean): void {
    this.loggedUserObs.next(value);
  }

  logout(): any {

    localStorage.clear();

    return this.http
    .post<any[]>(environment.restBaseUrl + '/logout', null, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Logout", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Logout", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  setIdResellerChoosed(idReseller){
    localStorage.setItem("idResellerChoosed",idReseller);
    this.setLoggedUserState(true);
  }

  getIdResellerChoosed(){
    if (localStorage.getItem("idResellerChoosed") != null && localStorage.getItem("idResellerChoosed") != undefined) {
      return localStorage.getItem("idResellerChoosed");
    }
  }

  forgotPwd(email: string): any {

    localStorage.clear();

    const mailObj: any = {
      email: email
    }

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/forgot', mailObj, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Forgot pwd", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Forgot pwd", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }

  changePwd(passwords: Object): any {

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/changepwd', passwords, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Change PWD", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Change PWD", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  activate(data: Object): any {

    localStorage.clear();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/activate', data, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;
        if (res.outcome.success === true) {
          this.logger.log("Activate", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Activate", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }

  invite(idUser: number): any  {

    let invite: any = { };

    return this.http
    .put<any[]>(environment.restBaseUrl + '/admin/user/invite/' + idUser, invite, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Forgot pwd", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Forgot pwd", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    console.log("getRequestOptions");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }
}
