import { Injectable } from "@angular/core";
import { Owner, OwnerAction } from "../interfaces/owner.interface";
import { LoggingService } from "./log.service";
import { SettingsService } from "../settings/settings.service";
import { RestfulService } from "./restful.service";
import { Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { RequestBody } from "../interfaces/admin.interface";
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { getEndPointWithParams } from '../../../assets/js/util.js';

@Injectable()
export class OwnerService {

  typeActionOwner: OwnerAction;
  OwnerAction = OwnerAction;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }

  setTypeActionOwner(typeActionOwner: OwnerAction): void {
    this.typeActionOwner = typeActionOwner;
  }

  getTypeActionOwner(): OwnerAction {
    return this.typeActionOwner;
  }

  getOwnerList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.owner_all;
    return this.post(endPoint, reqBody);
  }

  getOwnerById(ownerId: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.owner_info, ownerId);
    return this.get(endPoint);
  }

  createOwner(reqBody: Owner): Observable<any> {
    const endPoint = ENDPOINT.owner;
    return this.post(endPoint, reqBody);
  }

  updateOwner(reqBody: Owner): Observable<any> {
    const endPoint = ENDPOINT.owner;
    return this.put(endPoint, reqBody);
  }

  enableOwner(ownerId: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.owner_enable, ownerId);
    return this.put(endPoint, null);
  }

  disableOwner(ownerId: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.owner_disable, ownerId);
    return this.put(endPoint, null);
  }

  /** REST METHODS */

  post(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

}
