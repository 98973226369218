<div class="modal-footer d-flex justify-content-between flex-row flex-wrap">
  <div class="amount-due">
      {{ 'base.amount_due' | translate }} {{ amountDue | currency:currency }}
  </div>
  <div>
      <button type="button"
          (click)="cancel()"
          class="btn-sm btn-cancel mr-3">{{ 'form.discard' | translate | uppercase }}</button>
      <button class="btn-sm btn-action"
          (click)="addToCart()"
          [disabled]="disabled"
          type="button">{{ 'form.add_to_cart' | translate | uppercase }}</button>
  </div>
</div>