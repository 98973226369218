<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title">
      <span *ngIf="translateTitle">{{ title | translate }}</span>
      <span *ngIf="!translateTitle">{{ title }}</span>
    </h4>
    <button *ngIf="closeIconVisible"
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!loading; else loadingTemplate" class="card-body">
      <ng-content select="[body]"></ng-content>
    </div>
    <ng-template #loadingTemplate>
      <div class="d-flex align-items-center justify-content-center my-5">
        <div class="ball-pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>