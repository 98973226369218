import { Component, OnInit } from '@angular/core';
import { PortalService } from '../../../core/services/portal.service';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  backendVersion: string = null;


  
  constructor(public settings: SettingsService, private portalService: PortalService) { }

  ngOnInit() {
    this.portalService.getBackendVersion().subscribe((response) => {
      const { version } = response;
      this.backendVersion = version;
    });
  }

}
