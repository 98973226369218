<div class="row">
  <!-- Intestatario -->
  <div *ngIf="!hideInputInCommon" class="form-group col-sm-6">
      <label for="city">{{ 'form.name_surname' | translate }}</label>
      <input [(ngModel)]="info.groupLeaderName"
        (ngModelChange)="updateInfo()"
        required
        type="text"
        name="groupLeaderName"
        placeholder="{{'form.name_surname' | translate }}"
        class="form-control"
        ngModel
        #groupLeaderName="ngModel">
  </div>
  <!-- Destinazione -->
  <div class="form-group col-sm-6">
    <label for="destination">{{ 'form.destination' | translate }}</label>
    <input [(ngModel)]="info.destination"
      (ngModelChange)="updateInfo()"
      required
      type="text"
      placeholder="{{ 'form.destination' | translate }}"
      name="destination"
      class="form-control"
      ngModel
      #destination="ngModel">
  </div>
  <!-- Data volo -->
  <s1-date-picker *ngIf="!hideInputInCommon" class="col-sm-4" [(date)]="info.date" (dateChange)="updateInfo()" [label]="'form.fly_date'"></s1-date-picker>
  <!-- Compagnia aerea -->
  <div class="form-group col-sm-6">
    <label for="airline">{{ 'form.airline' | translate }}</label>
    <input [(ngModel)]="info.airline"
      (ngModelChange)="updateInfo()"
      required
      type="text"
      name="airline"
      placeholder="{{ 'form.airline' | translate }}"
      class="form-control"
      ngModel
      #airline="ngModel">
  </div>
</div>