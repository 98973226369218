import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { RestfulService } from '../../../../core/services/restful.service';
import { LoggingService } from '../../../../core/services/log.service';
import { SettingsService } from '../../../../core/settings/settings.service';
import * as ENDPOINT from "../../../../shared/constant/endPoint.js";
import { Observable } from 'rxjs';

@Injectable()
export class UserblockService {

  public userBlockVisible: boolean;

  constructor(
    private logger: LoggingService,
    private settings: SettingsService,
    private restful: RestfulService
  ) {
    // initially visible
    this.userBlockVisible = true;
  }

  getVisibility() {
    return this.userBlockVisible;
  }

  setVisibility(stat = true) {
    this.userBlockVisible = stat;
  }

  toggleVisibility() {
    this.userBlockVisible = !this.userBlockVisible;
  }

  getBusinessName() {
    const endPoint = ENDPOINT.admin_userBusinessName;
    return this.get(endPoint);
  }


  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}
