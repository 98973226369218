<div class="item user-block mb-2"
    *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle"
                [src]="userProfileInfo.profilePicture"
                (error)="updateImage($event)"
                alt="Avatar" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">{{ userProfileInfo.name || 'nan' }} {{ userProfileInfo.surname || 'nan' }}</span>
        <span class="user-block-businessName"> {{ userProfileInfo.businessName }}</span>
        <span class="user-block-role"> {{ userProfileInfo.codeRole }}</span>
    </div>
</div>
