import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';



@Injectable()
export class RestfulService {

  public baseurl = environment.restBaseUrl;

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) { }

  // POST http request
  post(endPoint, reqBody) {
    const url = this.baseurl + endPoint;
    return this.http
      .post<any[]>(url, reqBody, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

    // POST http request
    post_downloadFile(endPoint, reqBody) {
        let url = this.baseurl + endPoint;
        return this.http
            .post<any[]>(url, reqBody, this.getRequestOptionArgs_downlodFile())
            .pipe(map(
                (response: HttpResponse<any>) => response
            ), catchError((errorResponse: HttpErrorResponse) => {
                const res: any = errorResponse.error;
                return throwError(errorResponse.error);
            }
            ));
    }

    //GET http request
    get(endPoint) {
        const url = this.baseurl + endPoint;
        return this.http
            .get<any[]>(url, this.getRequestOptionArgs())
            .pipe(map(
                (response: HttpResponse<any>) => response
            ), catchError((errorResponse: HttpErrorResponse) => {
                const res: any = errorResponse.error;
                if (res.outcome.code === "0005" || res.outcome.code === "0007") {
                    this.settings.sessionExpired();
                }
                return throwError(errorResponse.error);
            }
            ));
    }

  // PUT http request
  put(endPoint, reqBody) {
    const url = this.baseurl + endPoint;
    return this.http
      .put<any[]>(url, reqBody, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // PUT http request
  put_DownloadFile(endPoint, reqBody) {
    const url = this.baseurl + endPoint;
    return this.http
      .put<any[]>(url, reqBody, this.getRequestOptionArgs_downlodFile())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), /* catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      } */catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        return throwError(errorResponse.error);
      }
      ));
  }

  // DELETE http request
  delete(endPoint) {
    const url = this.baseurl + endPoint;
    return this.http
      .delete<any[]>(url, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

    getRequestOptionArgs(): any {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
            })
        };
        return httpOptions;
    }

    getRequestOptionArgs_downlodFile(): any {
        const httpOptions = {
            responseType: "blob",
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
            })
        };
        return httpOptions;
    }
}

