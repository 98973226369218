import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { RequestBody } from '../interfaces/admin.interface';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import { ResellerAction, Reseller } from '../interfaces/reseller.interface';


@Injectable({
  providedIn: 'root'
})
export class ResellerService {

  typeAction: ResellerAction;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }


  resellerList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.reseller_all;
    return this.post(endPoint, reqBody);
  }

  getResellerInfo(idReseller): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.reseller_info, idReseller);
    return this.get(endPoint);
  }


  deleteAssociation(idReseller, idCompany): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.reseller_deleteAssociation,
      idReseller, idCompany);
    return this.delete(endPoint);
  }

  createReseller(resellerInfo: Reseller): Observable<any> {
    const endPoint = ENDPOINT.reseller_createReseller;
    return this.post(endPoint, resellerInfo);
  }

  updateReseller(resellerInfo: Reseller): Observable<any> {
    const endPoint = ENDPOINT.reseller_update;
    return this.put(endPoint, resellerInfo);
  }


  setTypeAction(typeAction: ResellerAction): void {
    this.typeAction = typeAction;
  }

  getTypeAction(): ResellerAction {
    return this.typeAction;
  }

  getUserMyReseller() {
    const endpoint = ENDPOINT.admin_user_myReseller;
    return this.get( endpoint );
  }

  getUserAllReseller() {
    const endpoint = ENDPOINT.admin_user_allReseller;
    return this.get( endpoint );
  }

  getUserSessionMetadata() {
    const endpoint = ENDPOINT.admin_user_session_metadata;
    return this.get( endpoint );
  }

  setUserSessionMetadata( reqBody: any ) {
    const endpoint = ENDPOINT.admin_user_session_metadata;
    return this.put( endpoint, reqBody );
  }



  post(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }


  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}



