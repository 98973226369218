import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'italianTime'
})
export class ItalianTimePipe implements PipeTransform  {

  transform(timestamp: Date, ...args: unknown[]): null
  transform(timestamp: Date, ...args: unknown[]): string {

    if (!timestamp) {
      return null
    }

    const date = new Date(timestamp);

    const dateS = date.toLocaleDateString("IT-it", {timeZone: 'Europe/Rome', day: '2-digit', month: '2-digit', year: 'numeric'});
    const timeS = date?.toLocaleTimeString("IT-it", {timeZone: 'Europe/Rome'});

    return dateS + " " + timeS;

  }

}
