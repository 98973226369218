<div class="row mt-3" *ngIf="quantityArray.length == product.thresholdAttribute.length">        

  <ng-container *ngFor="let thAt of product.thresholdAttribute; let i = index">

    <div class="form-group col-sm-4">
      <label for="code">{{ thAt.name }}</label>
      <input [(ngModel)]="quantityArray[i].quantity"
          (ngModelChange)="calcAmountDue()"
          min=0
          type="number"
          required
          name="quantity-{{i}}"
          class="form-control"
          ngModel
          #quantity="ngModel">
          <div class="align-self-center">
            <small *ngIf="quantityArray[i].quantity > getMaxThreshold(thAt)"
                class="text-danger">
                {{ 'productManagement.thresholdsError.over_max' | translate }} {{ getMaxThreshold(thAt) }}
            </small>
          </div>
    </div>

  </ng-container>

</div>
