import { Component, Input, OnInit } from '@angular/core';
import { AccessChannelResponse } from '../../../core/interfaces/obliteration.interface';

export enum BadgeType {
  accessState = 0,
  badge = 1
}

@Component({
  selector: 'app-obliteration-status-badge',
  templateUrl: './obliteration-status-badge.component.html',
  styleUrls: ['./obliteration-status-badge.component.scss']
})
export class ObliterationStatusBadgeComponent implements OnInit {

  @Input() badgeType: number;
  @Input() statusObliteration: AccessChannelResponse;

  AccessChannelResponse = AccessChannelResponse;
  BadgeType = BadgeType;

  constructor() { }

  ngOnInit(): void {
  }

}
