  <div class="col-auto pt-4">
    <s1-button class="mt-4" [type]="s1ButtonType.Add" [outline]="true" (onClick)="openUploadModal()"></s1-button>
  </div>
  <div class="row">
    <div class="col-6 p-3" *ngFor="let media of _mediaList">
      <div class="w-100 bordered rounded shadow-sm p-3">
        <a  *ngIf="media.key[0].itemName.startsWith(translationAttributePrefix.pdf && !translationAttributePrefix.pdfImgProduct)" [href]="media.value" target="_blank">
          <div class="w-100 text-center cursor-pointer">
            <i class="fas fa-file-pdf fa-4x text-danger"></i>
          </div>
        </a>
        <img src="{{ media.value }}" class="my-2 img-preview" *ngIf="media.key[0].itemName.startsWith(translationAttributePrefix.image) || media.key[0].itemName.startsWith(translationAttributePrefix.pdfImgProduct) || media.key[0].itemName.startsWith(translationAttributePrefix.pdfImgFamily)" />
        <p>{{ 'image_section.type' | translate }}: {{ media.key[0].itemName }}</p>
        <p>{{ 'image_section.lang' | translate }}: {{ media.languageCode[0].itemName }}</p>
        <s1-button class="mt-4 mb-4" [type]="s1ButtonType.Edit" [onlyIcon]="true" [outline]="true" (onClick)="openUploadModal(media)"></s1-button>
        <s1-button class="mt-4 mb-4 ml-2" [type]="s1ButtonType.Delete" [onlyIcon]="true" [outline]="true" (onClick)="askDelete(media)"></s1-button>
      </div>
    </div>
  </div>