import { Injectable } from '@angular/core';
import { IDiscount } from '../models/discount';
import { Observable } from 'rxjs';
import { S1HttpClientService } from '../../s1/services/s1-http-client.service';
import { map } from 'rxjs/operators';

enum ProductApi {
  discounts = "/discounts"
}
// TODO: in future move all product api call here
@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

  path = "/product"

  constructor(private s1HttpClient: S1HttpClientService) { }

  public listaDiscounts(idProduct: number): Observable<IDiscount[]> {

    return this.s1HttpClient.get(this.path +`/${idProduct}` + ProductApi.discounts).pipe(
      map(response => { 
        return response.data
      })
    )

  }

}