<!--<ng-container *ngIf="!generateTicket">
  <div class="d-flex my-5 justify-content-center align-items-center">
    <span class="icon-info"></span>
    <div class="w-400">{{ 'productManagement.accessChannelErr.generate_ticket' | translate}}</div>
  </div>
</ng-container>-->

<ng-container *ngIf="generateTicket === true">

  <div class="w-100 text-center" *ngIf="_product"> {{ _product.nameProductInCombo }}</div>

  <!-- ALL -->
  <div class="row align-items-center mt-3 mb-4">
    <div class="col-sm-6 d-flex justify-content-between align-items-center">
      <div class="check-label mr-3">
        <label class="switch switch-lg m-0">
          <input type="checkbox" [checked]="channelAllIsSelected" (click)="onChannelAllToggleSelect($event)">
          <span class="mr-2"></span>
          {{ 'productManagement.label.all_channel' | translate }}
        </label>
      </div>
      <div class="flex-fill">
        <input type="number"
          placeholder="+inf"
          min=0
          name="all"
          #all="ngModel"
          class="w-100"
          [disabled]="!channelAllIsSelected"
          [ngModel]="obliterationALLQuantity"
          (ngModelChange)="quantityObliterationChanged(0, true, $event)" />
      </div>
    </div>
  </div>
  <hr class="my-4" />
  <!-- CANALI -->
  <div class="row align-items-center mt-3 mb-5">
    <div *ngFor="let channel of obliterationList; let i = index" [ngClass]="{'col-sm-6': channel.channelName !== 'ACALL'}">
      <ng-container *ngIf="channel.channelName !== 'ACALL'">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="check-label checkbox c-checkbox mr-3">
            <label class="w-100">
              <input type="checkbox"
                id="{{i}}-channel"
                name="{{i}}-channel"
                (click)="onSelectChannel(channel)"
                [checked]="channel.checked || accessChannelType === AccessChannel.ACALL"
                [disabled]="accessChannelType === AccessChannel.ACALL"
                placeholder="+inf" />
              <span class="fa fa-check"></span>
              {{ channel.channelName }}
            </label>
          </div>
          <div class="flex-fill">
            <input type="number"
              min=0
              placeholder="+inf"
              name="{{i}}-channel-qnt"
              #channelqnt="ngModel"
              class="w-100"
              [ngModel]="channel.quantity"
              (ngModelChange)="quantityObliterationChanged(i, false, $event)"
              [disabled]="accessChannelType === AccessChannel.ACALL || !channel.checked" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
