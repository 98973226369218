export interface ICoupon {
  id?: number,
  name: string,
  state: CouponState,
  validFrom: Date,
  validTo: Date,
  idCompany: number,
  value: number,
  code: string,
  type: CouponType,
  usageType: CouponUsageType,
  count: number,
  countSingle: number
}

export enum CouponState {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  NOT_USABLE = "NOT_USABLE"
}

export enum CouponType {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE"
}

export enum CouponUsageType {
  ALL = "ALL",
  CUSTOMER = "CUSTOMER",
  USER = "USER"
}

export interface ICouponAssociation {
  id: number,
  idCoupon: number,
  nameCoupon: string,
  codeCoupon: string,
  state: StateUsageCoupon,
  user?: IUserCoupon,
  customer?: ICustomerCoupon,
  usedTime?: Date,
}

export interface IUserCoupon {
  id: number,
  name: string,
  surname: string
}

export interface ICustomerCoupon extends IUserCoupon {}

export enum StateUsageCoupon {
  USED = "USED",
  ASSOCIATE = "ASSOCIATE"
}