import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { S1HttpClientService } from '../../s1/services/s1-http-client.service';
import { map } from 'rxjs/operators';
import { IRelatedProduct } from '../models/related-product';

enum RelatedProductApi {
  lista = "/all"
}

@Injectable({
  providedIn: 'root'
})
export class RelatedProductApiService {

  path = "/related/product"

  constructor(private s1HttpClient: S1HttpClientService) { }

  public lista(idProduct: number): Observable<IRelatedProduct[]> {

    return this.s1HttpClient.get(`${this.path}${RelatedProductApi.lista}/${idProduct}`).pipe(
      map(response => { 
        return response.data
      })
    )

  }

  public crea(relatedProduct: IRelatedProduct): Observable<boolean> {

    return this.s1HttpClient.post(`${this.path}`,relatedProduct).pipe(
      map(response => { 
        return response.outcome.success
      })
    )

  }

  public modifica(relatedProduct: IRelatedProduct): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}`,relatedProduct).pipe(
      map(response => { 
        return response.outcome.success
      })
    )

  }

  public elimina(idRelatedProduct: number): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}/${idRelatedProduct}`).pipe(
      map(response => { 
        return response.outcome.success
      })
    )

  }

} 